<template>
  <div>
    <div class="ui-filterbar" v-show="showPinFilters">
      <div class="ui-filterbar__content">
        <div>
          <span>{{ $t("role.orders.rounds.filters.activeTitle") }}:</span>
          <span class="ml-3" v-for="filter in pinFilters" :key="filter.name"
            >{{ filter.label }}: {{ filter.value }}
            <a href="#" @click.prevent="onResetPinFilter(filter)"
              ><font-awesome-icon icon="times"
            /></a>
          </span>
        </div>
        <div>
          <a href="#" @click.prevent="onResetPinFilters"
            ><font-awesome-icon icon="times" />
            {{ $t("role.orders.rounds.filters.cancelBtn") }}</a
          >
        </div>
      </div>
    </div>

    <form @submit.prevent="onSearch">
      <CRow class="align-items-end">
        <CCol md="2">
          <CInput
            :class="{ 'form-group--highlighted': hasValue(filters.keyword) }"
            :label="$t('role.orders.rounds.filters.products.label')"
            :value="filters.keyword"
            @update:value="onSearchKeywordChange"
            :placeholder="$t('role.orders.rounds.filters.products.placeholder')"
          >
            <template #prepend-content>
              <font-awesome-icon icon="search" />
            </template>
          </CInput>
        </CCol>
        <CCol md="2">
          <UiSelect
            :class="{ 'form-group--highlighted': hasValue(filters.statusId) }"
            :label="$t('role.orders.rounds.filters.articleStatus.label')"
            :value="filters.statusId"
            :options="statusOptions"
            @update:value="onStatusSelected"
            custom
          />
        </CCol>
        <CCol md="2">
          <CSelect
            :class="{
              'form-group--highlighted': hasValue(filters.productTypeId),
            }"
            :label="$t('role.orders.rounds.filters.productType.label')"
            :value="filters.productTypeId"
            :options="productTypeOptions"
            @update:value="onProductTypeSelected"
            custom
          />
        </CCol>
        <CCol md="2">
          <CSelect
            :class="{
              'form-group--highlighted': hasValue(filters.productLineId),
            }"
            :label="$t('role.orders.rounds.filters.productLine.label')"
            :value="filters.productLineId"
            :options="productLineOptions"
            @update:value="onProductLineSelected"
            custom
          />
        </CCol>
        <CCol md="2" v-if="!initialized || warehouseOptions.length > 2">
          <CSelect
            :class="{
              'form-group--highlighted': hasValue(filters.warehouseId),
            }"
            :label="$t('role.orders.rounds.filters.wh.label')"
            :value="filters.warehouseId"
            :options="warehouseOptions"
            @update:value="onWarehouseSelected"
            custom
          />
        </CCol>
        <CCol col="auto">
          <CButton class="mb-3 mr-2" color="primary" type="submit">
            {{ $t("role.orders.rounds.filters.searchBtn") }}
          </CButton>
          <CButton
            class="mb-3"
            variant="outline"
            color="primary"
            @click.prevent="onReset"
          >
            {{ $t("role.orders.rounds.filters.resetBtn") }}
          </CButton>
        </CCol>
      </CRow>
    </form>
  </div>
</template>

<script>
import UiSelect from "../../../../../common/form/UiSelect";
import { mapState } from "vuex";
import EventBus from "../../../../../helpers/EventBus";
export default {
  name: "OrdersRoundsFilter",

  components: {
    UiSelect,
  },
  data() {
    return {
      initialized: false,
    };
  },

  computed: {
    ...mapState("rounds", ["round", "filters"]),

    statusOptions() {
      return [
        { value: "", label: this.$t("role.orders.rounds.filters.allOption") },
        ...this.filters.options.status,
      ];
    },

    productLineOptions() {
      return [
        { value: "", label: this.$t("role.orders.rounds.filters.allOption") },
        ...this.filters.options.productLine,
      ];
    },

    productTypeOptions() {
      return [
        { value: "", label: this.$t("role.orders.rounds.filters.allOption") },
        ...this.filters.options.productType,
      ];
    },

    warehouseOptions() {
      return [
        { value: "", label: this.$t("role.orders.rounds.filters.allOption") },
        ...this.filters.options.warehouse,
      ];
    },

    showPinFilters() {
      return this.$store.getters["rounds/havePinFilters"];
    },

    pinFilters() {
      const filters = [];
      const labels = {
        producerId: this.$t("role.orders.rounds.filters.pin.producers"),
        productId: this.$t("role.orders.rounds.filters.pin.product"),
        orderId: this.$t("role.orders.rounds.filters.pin.orders"),
        islandId: this.$t("role.orders.rounds.filters.pin.island"),
      };
      const pinFilters = this.$store.getters["rounds/pinFilters"];

      for (const filter in pinFilters) {
        const value = pinFilters[filter];
        if (value !== null && value.id) {
          filters.push({
            name: filter,
            label: labels[filter],
            value: value.label || value.id,
          });
        }
      }
      return filters;
    },
  },

  watch: {
    round() {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const self = this;
      const lang = this.$i18n.locale;
      const i18n = this.$i18n;
      const roleId = this.$store.state.role.id;
      const roundId = this.$store.state.rounds.round.id;

      self.initialized = false;
      this.$store
        .dispatch("rounds/initFilters", {
          roleId,
          roundId,
          lang,
          i18n,
        })
        .then(() => {
          self.initialized = true;
        });
    },

    changeViewType(value) {
      this.$emit("update:viewType", value);
    },

    onSearchKeywordChange(value) {
      this.$store.commit("rounds/updateKeyword", value);
    },

    onStatusSelected(value) {
      this.$store.commit("rounds/updateStatusId", value);
    },

    onProductTypeSelected(value) {
      this.$store.commit("rounds/updateProductTypeId", value);
    },

    onProductLineSelected(value) {
      this.$store.commit("rounds/updateProductLineId", value);
    },

    onWarehouseSelected(value) {
      this.$store.commit("rounds/updateWarehouseId", value);
    },

    onSearch() {
      EventBus.$emit("orders:applyFilters");
    },

    onResetPinFilters() {
      this.$store.commit("rounds/resetPinFilters");
      this.onSearch();
    },

    onResetPinFilter(filter) {
      this.$store.commit("rounds/resetPinFilter", [filter.name]);
      this.onSearch();
    },

    hasValue(value) {
      return value !== "";
    },

    onReset() {
      this.$store.commit("rounds/resetMainFilters");
      this.onSearch();
    },
  },
};
</script>
